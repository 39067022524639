.sidedrawer {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar { 
  // display: none;  /* Safari and Chrome */
  width: 8px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #2c2b2b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #96a1ba57;
}
  
.sidedrawer>.MuiDrawer-paperAnchorLeft{
  width: 350px;
  background-color: #252525 !important;
  color: #fffefd;
}

.sidedrawer>.MuiDrawer-paper{
  top: 45px;
  flex: 1 0 auto;
  height: 95%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-x: hidden;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.mdrawer_header{
  font-family: "Work Sans", sans-serif;
  color:#fff;
  font-size: 20px;
}

.gameside-hall-row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -65px;
  margin-left: 5px;
}

.sidebar-hall-col-card-img{
  border-radius: 15px;
  flex-shrink: 0;
  height: 150px;
  width: 150px;
}