#gameMine.game-bg-web{
  width:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

}

.game-bg-web::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

#gameMine .innerContainer {
  max-width: 400px;
  /* background-color:#111923; */
  /* background: url('../../assets/images/mine/mines_background.png'); */
  /* background-size: cover; */
}

.react-game-mine{
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
}

/* Chrome, Safari, Edge, Opera */
.react-game-mine input::-webkit-outer-spin-button,
.react-game-mine input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.react-game-mine input[type=number] {
  -moz-appearance: textfield;
}

.react-game-mine input { 
  text-align: center;
  color: white;
}

.react-game-mine button{
  color: white !important;;
}

.react-game-mine div.container-fluid div.col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-stars-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-stars-panel-bg {
  /* height: 100%;  */
  /* background-image: url("../../assets/images/mine/mine_center_box_empty.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;

}

.center-stars-panel-bg div.row {
  margin: 2px 0;
}

.center-stars-panel-bg div.row div.col img {
  min-width: 60px;
  margin: 0 2px;
  /* max-height: calc(50%/5); */
  max-width: calc(25vw/5);
}

.mine-bottom-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-control-panel {
 /* border-color: #D7A24E;
 border-radius: 10px;
 border-style: solid;
 border-width: 1px;
 background-color: #232837; */
}

.bottom-control-panel div.row {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.bottom-control-label {
  color: white;
  font-size: 17px;
}

.bottom-row-with-border {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-color: #D7A24E;
 border-radius: 8px;
 border-style: solid;
 border-width: 1px;
 background-color: #11141B;
 /* width: 80%; */
}

.bottom_row_with_border_left {
  display: flex;
  justify-content: left !important;
  /* float:left; */
}

.bottom_row_with_border_left img {
  max-width: 45px;
}

.bottom_row_with_border_mid input {
  width: max-content;
}

.bottom_row_with_border_right {
  display: flex;
  justify-content: right !important;
}

.bottom_row_with_border_right img {
  max-width: 45px;
}


.bottom_row_inline {
  width: 100%;
  /* display: inline; */
  /* justify-content: center; */
  /* align-items: center !important; */
}

.bottom-buttons-group {
  justify-content: space-around !important;
}

.bottom-table-with-border {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  color: white;
  border-color: #D7A24E;
  border-radius: 15px;
  border-style: solid;
  border-width: 1px;
  background-color: rgba(17, 20, 27, 0.2);
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  /* height: 100px; */
}

.bottom-table-game-started-1 {
  width: 100% !important;
}
.bottom-table-game-started-1 span{
  font-size: larger;
}

.bottom-table-game-started-2 {
  width: 100% !important;
}

.bottom-table-game-started-2 span{
  font-size: larger;
}

.bottom-table-game-started-2 img{
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.mine-cashout-modal {
  margin-top: 40vh;
  /* margin-left: 50%; */
  color: white;
}

@media only screen and (max-width: 428px) {
  .mine-cashout-modal {
    /* margin-top: 40vh; */
  }
}

.mine-cashout-modal .modal-header {
  border-bottom: none;
}

.mine-cashout-modal .modal-content{
  background-color: #673212;
  border: 3px solid #AA592A;
}

.mine-cashout-modal .modal-body {
  display: flex;
  justify-content: center;
}

.mine-cashout-modal .container-fluid .col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mine-modal-body img {
  width: 40px;
  margin-left: -60px;
}

.mine-modal-body-textfield {
  background-color: #5A2B0F;
}

.bottom-table-game-started-coin {
  display: flex;
  justify-content: right !important;
  
}

.bottom-table-game-started-coin img {
  max-width: 46px;
}

.mine-select-bg {
  background: url('../../assets/images/mine/keyin_mines_amount.png');
  background-repeat: no-repeat;
  background-position: 50%;
  height: 105px;
  margin-bottom: 5%;
}

.mine-bet-input-background {
  background: url('../../assets/images/mine/mine_bet_amount.png');
  background-position: center;
  background-repeat: no-repeat;
}
.mine-bet-input-background div fieldset {
  border: none;
}

.start-game-first-row-box {
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 160px;
}

.win-board-word {
  position: absolute;
  color: #895f45;
  font-weight: bold;
}
.total-profit-box {
  border-radius: 15px;
  padding: 3px 20px;
  background: rgba(169,126,99,0.7);
  display: flex;
  color: white !important;
}

.backlight-effect {
  left: 0;
  top: 0;
  width: 60px;
  mix-blend-mode: screen;
  padding: 5px 5px 0;
  border-radius: 99px;
}

.breathing-card-animation {
  animation: breathing-card 0.8s;
}

@keyframes breathing-card {
  50%  {transform: scale(0.8);}
}  

.boom-effect {
  left: -65%;
  top: -25%;
  max-width: unset !important;
  width: 150px;
  display: none;
}

.btn-click-effect:active {
  transition: 0.2s;
  transform: scale(0.93);
}

.bottom-panel-btn {
  min-width: 45px !important;
}

.box-min-width {
  min-width: 60px;
}