.crash-game-body{
  position: absolute;
  top:15%;
  // width: 20%;
  // height: 100%;
}

.canvas-crash{
  display: block;
}

.canvas-crash.boom{
  display: none;
}

.crash-rocket-div{
  // top: 8%;
  // position: absolute;
  display: flex;
  transform:(0);
  right: -100%;
}

.crash-rocket{
  width: 130px;
  height: 50px;
  // transform: rotate(0);
  // transition: transform 1s ease-in-out;
  // position: absolute;
}

.crash-rocket .rocket-fire {
  background: url("/assets/images/crash/yan.png");
  width: 2.0625rem;
  height: 3rem;
  background-size: 100% auto;
  background-position: 0 0;
  animation: fireanimate-crash 0.7s steps(1) infinite;
  transform: rotate(90deg);
  margin-top: 5%;
}

@keyframes fireanimate-crash {
  0% {
    background-position: 0px 0px;
 }
  5% {
    background-position: 0px -2.4375rem;
 }
  10% {
    background-position: 0px -7.875rem;
 }
  15% {
    background-position: 0px -10.3125rem;
 }
  20% {
    background-position: 0px -15.75rem;
 }
  25% {
    background-position: 0px -18.1875rem;
 }
  30% {
    background-position: 0px -21.125rem;
 }
  35% {
    background-position: 0px -26.4625rem;
 }
  40% {
    background-position: 0px -26.5rem;
 }
  45% {
    background-position: 0px -33.9375rem;
 }
  50% {
    background-position: 0px -44.375rem;
 }
  55% {
    background-position: 0px -67.9125rem;
 }
  60% {
    background-position: 0px -60.375rem;
 }
  65% {
    background-position: 0px -54.9375rem;
 }
  70% {
    background-position: 0px -49.5rem;
 }
  75% {
    background-position: 0px -44.2625rem;
 }
  80% {
    background-position: 0px -41.825rem;
 }
  85% {
    background-position: 0px -36.5875rem;
 }
  90% {
    background-position: 0px -25.95rem;
 }
  95% {
    background-position: 0px -26.125rem;
 }
  98% {
    background-position: 0px -20.875rem;
 }
  to {
    background-position: 0px -9.4375rem;
 }
}

.crash-bg {
  background :url("/assets/images/crash/background.png");
  background-repeat: no-repeat;
  height:60vh;
  background-size: 140% 100%;
  max-width: 1920px;
  max-height: 550px;
  // position: absolute;
  // top: 15%;
  // left: 0;
  // right: 0;
  // bottom: 0;
  margin: 0 5px 20px 5px;
}

@-webkit-keyframes star-fall-crash-star {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(0, 0);
    -ms-transform: scale(0.5) translate(0, 0);
    transform: scale(0.5) translate(0);
 }
  50% {
    opacity: 1;
    -webkit-transform: translate(200px, 200px);
    -ms-transform: translate(200px, 200px);
    transform: translate(200px, 200px);
 }
  to {
    opacity: 0;
    -webkit-transform: scale(1.2) translate(300px, 300px);
    -ms-transform: scale(1.2) translate(300px, 300px);
    transform: scale(1.2) translate(300px, 300px);
 }
}
@keyframes star-fall-crash-star {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(0, 0);
    -ms-transform: scale(0.5) translate(0, 0);
    transform: scale(0.5) translate(0);
 }
  50% {
    opacity: 1;
    -webkit-transform: translate(200px, 200px);
    -ms-transform: translate(200px, 200px);
    transform: translate(200px, 200px);
 }
  to {
    opacity: 0;
    -webkit-transform: scale(1.2) translate(300px, 300px);
    -ms-transform: scale(1.2) translate(300px, 300px);
    transform: scale(1.2) translate(300px, 300px);
 }
}
.crash-star .bg-star.show_1 .e-r {
  -webkit-animation: star-fall-crash-star 3s linear;
  animation: star-fall-crash-star 3s linear;
}
.crash-star .bg-star.show_2 .s-p {
  -webkit-animation: star-fall-crash-star 4s linear;
  animation: star-fall-crash-star 4s linear;
}
.crash-star .bg-star.show_3 .r-p {
  -webkit-animation: star-fall-crash-star 5s linear;
  animation: star-fall-crash-star 5s linear;
}
.crash-star .bg-star .l-star {
  background: url("/assets/images/crash/satellite.png");
  display: block;
  opacity: 0;
  position: absolute;
  left: 10%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 2.375rem;
  height: 2.5rem;
}
.crash-star .bg-star .l-star.s-p {
  left: 30%;
}
.crash-star .bg-star .l-star.r-p {
  left: 45%;
}

.crash-body-bet-amount{
  display: flex;
  align-items: center;
  background: #161f2c;
  border: 1px solid #4b5b74;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.crash-body-bet-target{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  grid-column: 1/3;
  grid-row: 1;
  background: #161f2c;
  border: 1px solid #4b5b74;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.crash-bet-auto{
  display: flex;
  justify-content: space-between;
}

.auto-stop__label{
  display: block;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #55657e;
}

.crash-body-bet-target .crash-button{
  margin-bottom: 5px;
  padding: 0;
  width: 40px;
  height: 25px;
  line-height: 23px;
  background-color: rgb(53, 68, 97);
  border-radius: 7px;
  font-weight: 600;
  font-size: 12px;
  color: white;
  transition: all .3s;
  border: 1px solid transparent;
  touch-action: manipulation;
  font: inherit;
  letter-spacing: inherit;
  text-align: center;
  cursor: pointer;
}

.crash-body-bet-target .crash-bet-amount{
  text-align: center;
  padding: 0px 45px;
  border-radius: 5px;
  font-size: 18px;
  min-width: 50px;
  line-height: 1;
  color: white;
  width: 25vh;
}

.crash-bet-target_value{
  min-width: 100px;
  text-align: center;
  padding: 0;
  width: 50px;
  font-family: Numbers,Montserrat;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: none;
  -moz-appearance: textfield;
  height: auto;
  max-width: 100%;
}

.crash-body-bet{
  justify-content: center;
  display: flex;
  right: 1%;
}

.crash-body-bet .crash-button{
  padding-top: 15%;
  margin-top: 12px;
  border-radius: 5px;
  font-size: 12px;
  min-width: 5vh; 
  line-height: 1;
  text-align: center;
  background-color: #55acee;
  box-shadow: 0px 5px 0px 0px #3c93d5;
  height: 12vh;
  width: 150px;
  pointer-events:auto;
}

.crash-body-bet .crash-button:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px;
}

.rocket-number.green{
  color: greenyellow;
}

.rocket-number.red{
  color: rgba(255, 17, 0, 0.712);
}

.rocket-boom {
  background: url("/assets/images/limbo/boom.png");
  width: 8.25rem;
  height: 7.0625rem;
  background-size: 100% auto;
  opacity: 0;
  background-position: 0 0;
  position: relative;
}
.rocket-boom.boom {
  // margin: -6% 0 0 -8%;
  animation: boom-crash 0.4s steps(1, end);
  // animation: boom-crash 0.4s steps(1) infinite;
}

.rocket-boom.boom2 {
  margin: -8% 0 0 -8%;
  animation: boom-crash 0.4s steps(1, end);
  // animation: boom-crash 0.4s steps(1) infinite;
}

@keyframes boom-crash {
  0% {
    opacity: 0;
 }
  48% {
    background-position: 0 -1rem;
    opacity: 0.5;
 }
  52% {
    background-position: 0 -16.6875rem;
 }
  56% {
    background-position: 0 -32.375rem;
 }
  60% {
    background-position: 0 -48.0625rem;
 }
  64% {
    background-position: 0 -62.75rem;
 }
  68% {
    background-position: 0 -72.1375rem;
 }
  72% {
    background-position: 0 -88.125rem;
 }
  76% {
    background-position: 0 -112.25rem;
    opacity: 1;
 }
  80% {
    background-position: 0 -127.5rem;
 }
  84% {
    background-position: 0 -143.4875rem;
 }
  88% {
    background-position: 0 -159.475rem;
 }
  92% {
    background-position: 0 -167.3625rem;
 }
  96% {
    background-position: 0 -183.25rem;
 }
  to {
    background-position: 0 -203.9375rem;
    opacity: 0;
 }
}

#crash_stars {
  margin: 0 auto;
  max-width: 1920px;
  max-height: 350px;
  // position:  absolute;
  // top: 10%;
  // left: 0;
  // right: 0;
  // bottom: 0;
}
.crash_star {
  display: block;
  width: 1px;
  background: transparent;
  position: relative;
  opacity: 0;
  /*过渡动画*/
  
  animation: crash_star-fall 2s linear infinite;
  -webkit-animation: crash_star-fall 2s linear infinite;
  -moz-animation: crash_star-fall 2s linear infinite;
}
.crash_star:after {
  content: '';
  display: block;
  border: 0px solid #fedd79;
  border-width: 0px 90px 2px 90px;
  border-color:transparent transparent transparent rgb(255, 241, 200);
  box-shadow: 0 0 1px 0 rgba(255, 255, 255, .1);
  /*变形*/
  
  transform: rotate(-45deg) translate3d(1px, 3px, 0);
  -webkit-transform: rotate(-45deg) translate3d(1px, 3px, 0);
  -moz-transform: rotate(-45deg) translate3d(1px, 3px, 0);
  transform-origin: 0% 100%;
  -webkit-transform-origin: 0% 100%;
  -moz-transform-origin: 0% 100%;
}
@keyframes crash_star-fall {
  0% {
      opacity: 0;
      transform: scale(0.5) translate3d(0, 0, 0);
      -webkit-transform: scale(0.5) translate3d(0, 0, 0);
      -moz-transform: scale(0.5) translate3d(0, 0, 0);
  }
  50% {
      opacity: 1;
      transform: translate3d(-200px, 200px, 0);
      -webkit-transform: translate3d(-200px, 200px, 0);
      -moz-transform: translate3d(-200px, 200px, 0);
  }
  100% {
      opacity: 0;
      transform: scale(1.2) translate3d(-300px, 300px, 0);
      -webkit-transform: scale(1.2) translate3d(-300px, 300px, 0);
      -moz-transform: scale(1.2) translate3d(-300px, 300px, 0);
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid;
  border-color: #7c7c7c transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.div-loader {
  display: block;
}

.player_cashout {
  position: absolute;
  // top: 0;
  // right: 10%;
  width: 100px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.705);
  animation: player-cashout 3s ease-in-out forwards;
  color:black;
  font-size: 12px;
}

@keyframes player-cashout {
  0% {
    top: 60%;
    opacity: 0.5;
  }
  100% {
    top: 80%;
    opacity: 0;
  }
}

.crash-top-panel{
  // position: absolute;
  width: 100%;
  // z-index: 999999;
  //pointer-events: auto;
}

.rules-method-container{
  overflow: auto;
  white-space: nowrap;
  width: 97vw;
  height: 65px;
  position: relative;
  color: white;
}

.rules-method-header{
  display: inline-block;
  min-width: 65px;
  text-align: center;
  padding-top: 0.5vh;
  background-repeat: no-repeat;
  height: 50%;
  margin: 0 6px 0 0;
}

::-webkit-scrollbar{
  display: none;
}

.history-background-0{
  background-image: url("/assets/images/crash/C1.png");
}
.history-background-1{
  background-image: url("/assets/images/crash/C2.png");
}
.history-background-2{
  background-image: url("/assets/images/crash/C3.png");
}
.history-background-3{
  background-image: url("/assets/images/crash/C4.png");
}
.history-background-4{
  background-image: url("/assets/images/crash/C5.png");
}
.history-background-5{
  background-image: url("/assets/images/crash/C6.png");
}
.history-background-6{
  background-image: url("/assets/images/crash/C7.png");
}
.history-background-7{
  background-image: url("/assets/images/crash/C8.png");
}
.history-background-8{
  background-image: url("/assets/images/crash/C9.png");
}

.crash-buttons{
  grid-template-columns: 1fr 105px;
  grid-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
  width: 100%;
  display: grid;
  // position: absolute;
  // bottom: 5%;
  padding-left: 16px;
  padding-right: 16px;
}

.amount_left{
  display: flex;
  flex-direction: column;
}

.amount_left .crash-button{
  margin-bottom: 5px;
  padding: 0;
  width: 40px;
  height: 25px;
  line-height: 23px;
  background-color: rgb(53, 68, 97);;
  border-radius: 7px;
  font-weight: 600;
  font-size: 12px;
  color: white;
  transition: all .3s;
  border: 1px solid transparent;
  touch-action: manipulation;
  font: inherit;
  letter-spacing: inherit;
  text-align: center;
  cursor: pointer;
}

.amount_left .crash-button span{
  font-weight: 600;
  font-size: 12px;
}

.amount_center{
  display: flex;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
}

.amount_center .crash-bet-amount{
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  padding: 0;
  width: 120px;
  font-family: Numbers,Montserrat;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: none;
}

.amount_center .crash-button span{
  font-weight: 600;
  font-size: 12px;
}

.amount_right{
  display: flex;
  flex-direction: column;
}

.amount_right .crash-button{
  margin-bottom: 5px;
  padding: 0;
  width: 40px;
  height: 25px;
  line-height: 23px;
  background-color: rgb(53, 68, 97);
  border-radius: 7px;
  font-weight: 600;
  font-size: 12px;
  color: white;
  transition: all .3s;
  border: 1px solid transparent;
  touch-action: manipulation;
  font: inherit;
  letter-spacing: inherit;
  text-align: center;
  cursor: pointer;
}

.amount_right .crash-button span{
  font-weight: 600;
  font-size: 12px;
}

.crash-button-red{
  grid-column: 2/3;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 18px;
  border-color: #ed1d49;
  background-color: #ed1d49;
  position: relative;
  border-radius: 7px;
  font-style: normal;
  transition: all .3s;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.4;
  cursor: pointer;
}


.buttonState{
  text-transform: uppercase;
  position: relative;
  pointer-events: none;
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 18px;
}

.buttonState_2{
  white-space: normal;
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: hsla(0,0%,100%,.75);
  pointer-events: none;
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 1;
  display: none;
}

.crash-bottom-panel{
  color: white;
  width: 95%;
  margin: 20px 0 10px 10px;
}
.stat_wrapper{
  padding: 5px 0;
  background: #161f2c;
  border: 1px solid rgba(121,137,162,.77);
  border-radius: 10px;
}
.stat_inner{
  height: 200px;
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 10px;
  color: white;
  text-align: center;
}
.crash-bottom-panel .row{
  display: flex;
  justify-content: space-between;
  line-height: 34px;
  height: 34px;
  border-bottom: 1px solid rgba(85,101,126,.18);
}
.crash-bottom-panel .row-header{
  display: flex;
  justify-content: space-between;
  line-height: 34px;
  height: 34px;
  border-bottom: 1px solid rgba(85,101,126,.18);
}
.crash-bottom-panel .td{
  flex: 1 1;
  padding-right: .5em;
  width: 133px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.crash-bottom-panel .td .period{
  // display: flex;
  align-items: center;
  overflow-y: hidden;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-family: Numbers, Montserrat;
  font-weight: 600;
}
.crash-bottom-panel .td .cashout{
  // color: #55657e;
  font-weight: 600;
  font-family: Numbers,Montserrat;
}
.crash-bottom-panel .td .bet{
  // color: #55657e;
  font-weight: 600;
  font-family: Numbers,Montserrat;
}
.crash-bottom-panel .td .payout{
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-family: Numbers, Montserrat;
  font-weight: 600;
}

.crash-web{
  margin: 0 30%;
}

.nextGameNotice{
  // text-align: center;
  color: #fff;
  transition: all 1s;
  position: relative;
  font-size: 4rem;
  white-space: nowrap;
  font-weight: 600;
}