.text-chillired{
  color:#fbed14!important
}

.navbar{
  min-height:44px;
  background-color:rgb(7, 33, 58);;
}

.navbar a.top-link {
  font-size: 13px;
  padding: 0 6px 0 10px;
}

.navbar a.link {
  padding: 0 10px;
  line-height: 44px;
  height: 44px;
}

.MuiPaper-root {
  background-color: #ffffff !important;
  width: auto;
}

.MuiDivider-root {
  background-color: #adadad !important
}

.error-page {
  p {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #a7a7a7;
  }

  table {
    text-align: center;
    height: 97vh;
    width: 100%;
  }

  table img {
    width: 220px;
  }

  td {
    height: 100%;
  }

  tr {
    height: 100%;
  }

  .time {
    color: #ff5252;
    font-weight: 800;
  }
}

.error-404-page {
  p {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #a7a7a7;
  }

  table {
    text-align: center;
    height: 97vh;
    width: 100%;
  }

  table img {
    width: 220px;
  }

  td {
    height: 100%;
  }

  tr {
    height: 100%;
  }

  .icon {
    border: 1px solid #d5d5d5;
    height: 38px;
    padding: 8px 36px;
    border-radius: 5px;
    width: 103px;
  }

  .icon:hover {
    background: #f7f7f7;
    border: 1px solid #d5d5d5;
    height: 38px;
    padding: 8px 36px;
    border-radius: 5px;
    width: 103px;
  }
}

.orientation-container{
  background: black;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
  }

  .message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }

    50% {
      transform: rotate(-90deg)
    }

    100% {
      transform: rotate(-90deg)
    }
  }
}

.dice-body {
  margin: 0 40px 0 40px;
}

.dice-body-left {
  justify-content: left;
  padding: 15px 0 0 15px;
}

.dice-body-left span{
  font-size: 38px;
}

.dice-body-right {
  justify-content: right;
  padding: 15px 15px 0 0;
}

.dice-body-right span{
  font-size: 38px;
}

.dice {
  background-position: 0% 100%;
  animation: .2s steps(5, start) random infinite;
}

.dice img{
  background-image: url("/assets/images/randomDice.png");
  width: 75px;
  height: 75px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: cover;
  animation: .5s steps(5, start) random infinite;
}

@keyframes random {
  to {
    background-position: 100% 100%;
  }
}

#dice {
  display: none;
}

.diceBlock{
  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-family: Helvetica;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(20px);
  width: 75px;
  height: 75px;
  position: absolute;
}

.diceBlock span{
  font-size: 60px;
}

.dice-header{
  height: 165px;
  padding: 25px 0 25px 0;
  display:flex;
  justify-content:center;
  background-color: rgb(116, 117, 125);
}

.dice-body-result{
  height: 70px;
  justify-content:center;
  background-color: rgb(144, 146, 150);
  margin: 5px 0 0 0;
}

.dice-body-select{
  height: 220px;
  justify-content:center;
  background-color: rgb(47, 53, 73);
  margin: 5px 0 0 0;
}

.dice-body-bet-amount{
  height: 100px;
  justify-content:center;
  background-color: rgb(148, 149, 153);
  margin: 5px 0 0 0;
  display:flex;
}

.dice-body-bet-amount .lg-button{
  padding: 6px 12px;
  background-color: rgba(88, 90, 206, 0.84);
  box-shadow: 0px 5px 0px 0px rgb(88,89,206);
  margin-top: 11px;
  border-radius: 5px;
  font-size: 12px;
  min-width: 50px;
  line-height: 1;
  text-align: center;
}

.dice-body-bet-amount .lg-button:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px;
}

.dice-bet-amount-body {
  margin: 35px 70px 0 70px;
}

.dice-bet-amount-body-left {
  justify-content: left;
  padding: 15px 0 0 15px;
}

// .dice-bet-body-left span{
//   font-size: 38px;
// }

.dice-bet-body-right {
  justify-content: right;
  padding: 15px 15px 0 0;
}

.dice-body-bet{
  height: 80px;
  justify-content:center;
  background-color: rgb(148, 149, 153);
  margin: 5px 0 0 0;
  display:flex;
}

.dice-body-bet .lg-button{
  padding: 6px 12px;
  margin-top: 11px;
  border-radius: 5px;
  font-size: 12px;
  min-width: 50px;
  line-height: 1;
  text-align: center;
  background-color: #55acee;
  box-shadow: 0px 5px 0px 0px #3C93D5;
}

.dice-body-bet .lg-button:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px;
}
// .dice-bet-body-right span{
//   font-size: 38px;
// }

.dice-body-select-under_over{
  background-color: rgb(28, 32, 45);
  height: 150px;
  display:flex;
}

.dice-body-select-under_over span{
  color: white;
}

.hori-line{
  height: 90%;
  border-right: solid #C0CBE9;
  margin-top: 8px;
}

.dice-body-select-under_over .dice-body-left-under_over{
  display:flex;
}

.dice-body-select-under_over .dice-body-right-under_over{
  display:flex;
}

.dice-body-select-under{
  padding: 30px 10px;
  margin-top: 25px;
  border-radius: 5px;
  font-size: 15px;
  /* min-width: 50px; */
  line-height: 1;
  text-align: center;
  background-color: rgb(184, 94, 23);
  height: 30px;
}

.dice-body-select-multiplier{
  width: 100px;
  text-align: center;
  margin-top: 55px;
  font-size: 14px;
}

.mobile-userdetails{
  background-repeat: no-repeat;
  background-size: 100%;
}

.mobile-hall-col-card-img{
  width: 100%;
}