@-webkit-keyframes star-fall-limbo-star {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(0, 0);
    -ms-transform: scale(0.5) translate(0, 0);
    transform: scale(0.5) translate(0);
 }
  50% {
    opacity: 1;
    -webkit-transform: translate(200px, 200px);
    -ms-transform: translate(200px, 200px);
    transform: translate(200px, 200px);
 }
  to {
    opacity: 0;
    -webkit-transform: scale(1.2) translate(300px, 300px);
    -ms-transform: scale(1.2) translate(300px, 300px);
    transform: scale(1.2) translate(300px, 300px);
 }
}
@keyframes star-fall-limbo-star {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(0, 0);
    -ms-transform: scale(0.5) translate(0, 0);
    transform: scale(0.5) translate(0);
 }
  50% {
    opacity: 1;
    -webkit-transform: translate(200px, 200px);
    -ms-transform: translate(200px, 200px);
    transform: translate(200px, 200px);
 }
  to {
    opacity: 0;
    -webkit-transform: scale(1.2) translate(300px, 300px);
    -ms-transform: scale(1.2) translate(300px, 300px);
    transform: scale(1.2) translate(300px, 300px);
 }
}
.limbo-star .bg-star.show_1 .e-r {
  -webkit-animation: star-fall-limbo-star 3s linear;
  animation: star-fall-limbo-star 3s linear;
}
.limbo-star .bg-star.show_2 .s-p {
  -webkit-animation: star-fall-limbo-star 4s linear;
  animation: star-fall-limbo-star 4s linear;
}
.limbo-star .bg-star.show_3 .r-p {
  -webkit-animation: star-fall-limbo-star 5s linear;
  animation: star-fall-limbo-star 5s linear;
}
.limbo-star .bg-star .l-star {
  background: url("/assets/images/limbo/star.png");
  display: block;
  opacity: 0;
  position: absolute;
  left: 10%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 2.375rem;
  height: 2.5rem;
}
.limbo-star .bg-star .l-star.s-p {
  left: 30%;
}
.limbo-star .bg-star .l-star.r-p {
  left: 45%;
}
.limbo {
  // position: absolute;
  width: 18.75rem;
  height: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-50%);
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
  margin : 0 50%;
}
@-webkit-keyframes rocketanimate-limbo {
  0% {
    left: 0.3125rem;
    top: 0.1875rem;
 }
  10% {
    left: 0.1875rem;
    top: 0.25rem;
 }
  20% {
    left: -0.0625rem;
    top: 0.0625rem;
 }
  30% {
    left: -0.1875rem;
    top: 0.125rem;
 }
  40% {
    left: 0.25rem;
    top: 0.3125rem;
 }
  50% {
    left: -0.125rem;
    top: 0;
 }
  60% {
    left: 0.1875rem;
    top: 0.125rem;
 }
  70% {
    left: 0.0625rem;
    top: 0.25rem;
 }
  80% {
    left: -0.0625rem;
    top: 0.0625rem;
 }
  90% {
    left: -0.125rem;
    top: 0.1875rem;
 }
  to {
    left: 0.1875rem;
    top: 0.3125rem;
 }
}
@keyframes rocketanimate-limbo {
  0% {
    left: 0.3125rem;
    top: 0.1875rem;
 }
  10% {
    left: 0.1875rem;
    top: 0.25rem;
 }
  20% {
    left: -0.0625rem;
    top: 0.0625rem;
 }
  30% {
    left: -0.1875rem;
    top: 0.125rem;
 }
  40% {
    left: 0.25rem;
    top: 0.3125rem;
 }
  50% {
    left: -0.125rem;
    top: 0;
 }
  60% {
    left: 0.1875rem;
    top: 0.125rem;
 }
  70% {
    left: 0.0625rem;
    top: 0.25rem;
 }
  80% {
    left: -0.0625rem;
    top: 0.0625rem;
 }
  90% {
    left: -0.125rem;
    top: 0.1875rem;
 }
  to {
    left: 0.1875rem;
    top: 0.3125rem;
 }
}
@-webkit-keyframes fireanimate-limbo {
  0% {
    background-position: 0px 0px;
 }
  5% {
    background-position: 0px -6.4375rem;
 }
  10% {
    background-position: 0px -12.875rem;
 }
  15% {
    background-position: 0px -19.3125rem;
 }
  20% {
    background-position: 0px -25.75rem;
 }
  25% {
    background-position: 0px -32.1875rem;
 }
  30% {
    background-position: 0px -38.625rem;
 }
  35% {
    background-position: 0px -45.0625rem;
 }
  40% {
    background-position: 0px -51.5rem;
 }
  45% {
    background-position: 0px -57.9375rem;
 }
  50% {
    background-position: 0px -64.375rem;
 }
  55% {
    background-position: 0px -70.8125rem;
 }
  60% {
    background-position: 0px -64.375rem;
 }
  65% {
    background-position: 0px -57.9375rem;
 }
  70% {
    background-position: 0px -51.5rem;
 }
  75% {
    background-position: 0px -45.0625rem;
 }
  80% {
    background-position: 0px -38.625rem;
 }
  85% {
    background-position: 0px -32.1875rem;
 }
  90% {
    background-position: 0px -25.75rem;
 }
  95% {
    background-position: 0px -19.3125rem;
 }
  98% {
    background-position: 0px -12.875rem;
 }
  to {
    background-position: 0px -6.4375rem;
 }
}
@keyframes fireanimate-limbo {
  0% {
    background-position: 0px 0px;
 }
  5% {
    background-position: 0px -6.4375rem;
 }
  10% {
    background-position: 0px -12.875rem;
 }
  15% {
    background-position: 0px -19.3125rem;
 }
  20% {
    background-position: 0px -25.75rem;
 }
  25% {
    background-position: 0px -32.1875rem;
 }
  30% {
    background-position: 0px -38.625rem;
 }
  35% {
    background-position: 0px -45.0625rem;
 }
  40% {
    background-position: 0px -51.5rem;
 }
  45% {
    background-position: 0px -57.9375rem;
 }
  50% {
    background-position: 0px -64.375rem;
 }
  55% {
    background-position: 0px -70.8125rem;
 }
  60% {
    background-position: 0px -64.375rem;
 }
  65% {
    background-position: 0px -57.9375rem;
 }
  70% {
    background-position: 0px -51.5rem;
 }
  75% {
    background-position: 0px -45.0625rem;
 }
  80% {
    background-position: 0px -38.625rem;
 }
  85% {
    background-position: 0px -32.1875rem;
 }
  90% {
    background-position: 0px -25.75rem;
 }
  95% {
    background-position: 0px -19.3125rem;
 }
  98% {
    background-position: 0px -12.875rem;
 }
  to {
    background-position: 0px -6.4375rem;
 }
}
@-webkit-keyframes boom-limbo {
0% {
    opacity: 0;
}
48% {
     background-position: 0 20px;
    opacity: 0.5;
}
52% {
    background-position: 0 -19.6875rem;
}
56% {
    background-position: 0 -26.375rem;
}
60% {
    background-position: 0 -47.0625rem; 
}
64% {
    background-position: 0 -54.75rem;
}
68% {
    background-position: 0px -75.4375rem;
}
72% {
    background-position: 0px -97.125rem;
}
76% {
    background-position: 0 -109.8125rem;
    opacity: 1;
}
80% {
    background-position: 0 -123.5rem;
}
84% {
    background-position: 0 -138.1875rem;
}
88% {
    background-position: 0 -151.875rem;
}
92% {
    background-position: 0 -172.5625rem;
}
96% {
    background-position: 0 -188.25rem;
}
100% {
    background-position: 0 -200.9375rem;
    opacity: 0;
}
}
@keyframes boom-limbo {
  0% {
    opacity: 0;
}
48% {
     background-position: 0 20px;
    opacity: 0.5;
}
52% {
    background-position: 0 -19.6875rem;
}
56% {
    background-position: 0 -26.375rem;
}
60% {
    background-position: 0 -47.0625rem; 
}
64% {
    background-position: 0 -54.75rem;
}
68% {
    background-position: 0px -75.4375rem;
}
72% {
    background-position: 0px -97.125rem;
}
76% {
    background-position: 0 -109.8125rem;
    opacity: 1;
}
80% {
    background-position: 0 -123.5rem;
}
84% {
    background-position: 0 -138.1875rem;
}
88% {
    background-position: 0 -151.875rem;
}
92% {
    background-position: 0 -172.5625rem;
}
96% {
    background-position: 0 -188.25rem;
}
100% {
    background-position: 0 -200.9375rem;
    opacity: 0;
}
}
.limbo.auto {
  top: 20%;
}
.limbo .limbo-rocket {
  position: relative;
  width: 100%;
  height: 100%;
}
.limbo .limbo-rocket .rocket-number {
  text-align: center;
  color: #fff;
  -webkit-transition: all 1s;
  transition: all 1s;
  position: relative;
  font-size: 4.25rem;
  //white-space: nowrap;
  font-weight: 600;
}
.limbo .limbo-rocket .rocket-number .rocket-number-x {
  display: inline-block;
}
.limbo .limbo-rocket .rocket-number .rocket-boom {
  background: url("/assets/images/limbo/boom.png");
  width: 7.25rem;
  height: 8.0625rem;
  background-size: 100% auto;
  opacity: 0;
  background-position: 0 0;
  top: 80%;
  position: absolute;
  left: 75%;
  margin-left: -8.125rem;
  margin-top: -7.5rem;
  transform: scale(1.5);
}
.limbo .limbo-rocket .rocket-number .rocket-boom.boom {
  -webkit-animation: boom-limbo 0.4s steps(1, end);
  animation: boom-limbo 0.4s steps(1, end);
}
.limbo .limbo-rocket .rocket-wrap {
  width: 18.625rem;
  margin: 0 auto;
  opacity: 1;
  position: absolute;
  bottom: 28%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-50%);
  bottom: -80%;
}
.limbo .limbo-rocket .rocket-wrap .rocket-img {
  margin: 0 auto;
  width: 6.25rem;
  height: 17.125rem;
  position: relative;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}
.limbo .limbo-rocket .rocket-wrap .rocket-img img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-animation: rocketanimate-limbo 2s linear infinite;
  animation: rocketanimate-limbo 2s linear infinite;
}
.limbo .limbo-rocket .rocket-wrap .rocket-fire {
  background: url("/assets/images/limbo/yan.png");
  margin: 0 auto;
  margin-top: -7rem;
  width: 5.0625rem;
  height: 6rem;
  background-size: 100% auto;
  background-position: 0 0;
  -webkit-animation: fireanimate-limbo 0.7s steps(1) infinite;
  animation: fireanimate-limbo 0.7s steps(1) infinite;
}
.limbo .limbo-rocket .rocket-wrap.flying {
  bottom: 20%;
  -webkit-transition: bottom 0.5s ease-in;
  transition: bottom 0.5s ease-in;
}
.limbo .limbo-rocket .rocket-wrap.boom {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translate(-50%, -120%);
  -ms-transform: translate(-50%, -120%);
  transform: translate(-50%, -120%);
  opacity: 0;
}
.limbo .limbo-rocket.limbo-turbo .rocket-number {
  -webkit-transform: scale(1.3) translateY(50%);
  -ms-transform: scale(1.3) translateY(50%);
  transform: scale(1.3) translateY(50%);
  -webkit-transition: all 1s;
  transition: all 1s;
  font-weight: 700;
}
.limbo .limbo-rocket.limbo-turbo .rocket-wrap, .limbo .limbo-rocket.limbo-turbo .rocket-boom {
  display: none;
}
@media screen and (max-width: 621px) {
  .limbo .limbo-rocket .rocket-number {
    margin-top: 0.25rem;
    font-size: 3.125rem;
    line-height: 1.15;
 }
  .limbo .limbo-rocket .rocket-wrap {
    bottom: 32%;
    width: 5rem;
 }
  .limbo .limbo-rocket .rocket-wrap .rocket-img {
    width: 3.75rem;
    height: 10.5rem;
 }
  .limbo .limbo-rocket .rocket-wrap .rocket-fire {
    margin-top: -5.625rem;
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6);
 }
  .limbo.auto .limbo-rocket .rocket-wrap {
    bottom: -80%;
 }
  .limbo.auto .limbo-rocket .rocket-wrap.flying {
    bottom: 80%;
 }
}
.limbo-bg {
  background :url("/assets/images/limbo/background.png");
  background-repeat: no-repeat;
  height:80vh;
  background-size: 140% 100%;
  max-width: 1920px;
  max-height: 550px;
  margin: 60px 5px 20px 5px;
}

.limbo-body-bet-amount{
  display: flex;
  align-items: center;
  background: #161f2c;
  border: 1px solid #4b5b74;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.limbo-bet-amount{
  text-align: center;
  padding: 8px 45px;
  border-radius: 5px;
  font-size: 18px;
  min-width: 50px;
  line-height: 1;
  color: white;
  // margin-bottom: 18px;
}

.limbo-body-bet-target{
display: flex;
align-items: center;
flex-direction: row-reverse;
justify-content: space-between;
grid-column: 1/3;
grid-row: 1;
background: #161f2c;
border: 1px solid #4b5b74;
border-radius: 10px;
padding: 10px;
position: relative;
}

.limbo-body-bet-target .limbo-button{
  margin-bottom: 5px;
  padding: 0;
  width: 40px;
  height: 25px;
  line-height: 23px;
  background-color: rgb(53, 68, 97);
  border-radius: 7px;
  font-weight: 600;
  font-size: 12px;
  color: white;
  transition: all .3s;
  border: 1px solid transparent;
  touch-action: manipulation;
  font: inherit;
  letter-spacing: inherit;
  text-align: center;
}

.limbo-body-bet-target .limbo-bet-amount{
  text-align: center;
  padding: 0px 55px;
  border-radius: 5px;
  font-size: 18px;
  min-width: 50px;
  line-height: 1;
  color: white;
  width: 28vh;
}

.limbo-body-bet{
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 60px;
  right: 125px;
}

.limbo-body-bet .limbo-button{
  padding-top: 100%;
  margin-top: 12px;
  border-radius: 5px;
  font-size: 12px;
  /* min-width: 12vh; */
  line-height: 1;
  text-align: center;
  background-color: #55acee;
  box-shadow: 0px 5px 0px 0px #3c93d5;
  height: 10vh;
  width: 500%;
}

.limbo-body-bet .limbo-button:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px;
}

.rocket-number.green{
  color: greenyellow;
}

.rocket-number.red{
  color: rgba(255, 17, 0, 0.712);
}

.limbo-bottom-panel{
  color: white;
  width: 95%;
  margin: 20px 0 10px 10px;
}
.stat_wrapper{
  padding: 5px 20px;
  background: #161f2c;
  border: 1px solid rgba(121,137,162,.77);
  border-radius: 10px;
}
.stat_inner{
  height: 200px;
  overflow-y: auto;
  margin-right: -10px;
  padding-right: 10px;
  color: white;
}
.limbo-bottom-panel .row{
  display: flex;
  justify-content: space-between;
  line-height: 34px;
  height: 34px;
  border-bottom: 1px solid rgba(85,101,126,.18);
}
.limbo-bottom-panel .td{
  flex: 1 1;
  padding-right: .5em;
  width: 133px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.limbo-bottom-panel .td .period{
  display: flex;
  align-items: center;
  overflow-y: hidden;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-family: Numbers, Montserrat;
  font-weight: 600;
}
.limbo-bottom-panel .td .cashout{
  // color: #55657e;
  font-weight: 600;
  font-family: Numbers,Montserrat;
}
.limbo-bottom-panel .td .bet{
  // color: #55657e;
  font-weight: 600;
  font-family: Numbers,Montserrat;
}
.limbo-bottom-panel .td .payout{
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-family: Numbers, Montserrat;
  font-weight: 600;
}

.limbo-buttons{
  grid-template-columns: 1fr 105px;
  grid-column-gap: 10px;
  column-gap: 5px;
  grid-row-gap: 10px;
  row-gap: 10px;
  width: 100%;
  display: grid;
  margin-top: 50%;
  padding-left: 10px;
  padding-right: 16px;
}

.amount_left{
  display: flex;
  flex-direction: column;
}

.amount_left .limbo-button{
  margin-bottom: 5px;
  padding: 0;
  width: 40px;
  height: 25px;
  line-height: 23px;
  background-color: rgb(53, 68, 97);;
  border-radius: 7px;
  font-weight: 600;
  font-size: 12px;
  color: white;
  transition: all .3s;
  border: 1px solid transparent;
  touch-action: manipulation;
  font: inherit;
  letter-spacing: inherit;
  text-align: center;
}

.amount_left .limbo-button span{
  font-weight: 600;
  font-size: 12px;
}

.amount_center{
  display: flex;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
}

.amount_center .limbo-bet-amount{
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  padding: 0;
  width: 120px;
  font-family: Numbers,Montserrat;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: none;
  height: 60px;
}

.amount_center .limbo-button span{
  font-weight: 600;
  font-size: 12px;
}

.amount_right{
  display: flex;
  flex-direction: column;
}

.amount_right .limbo-button{
  margin-bottom: 5px;
  padding: 0;
  width: 40px;
  height: 25px;
  line-height: 23px;
  background-color: rgb(53, 68, 97);
  border-radius: 7px;
  font-weight: 600;
  font-size: 12px;
  color: white;
  transition: all .3s;
  border: 1px solid transparent;
  touch-action: manipulation;
  font: inherit;
  letter-spacing: inherit;
  text-align: center;
}

.amount_right .limbo-button span{
  font-weight: 600;
  font-size: 12px;
}

.limbo-button-blue{
  grid-column: 2/3;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 18px;
  border-color: #2283f6;
  background-color: #2283f6;
  position: relative;
  border-radius: 7px;
  font-style: normal;
  transition: all .3s;
  color: #fff;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.4;
  cursor: pointer;
  height: 45%;
}

.buttonState{
  text-transform: uppercase;
  position: relative;
  pointer-events: none;
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 18px;
}

.limbo-bet-auto{
  display: flex;
  justify-content: space-between;
}

.css-1tved01-MuiFormControl-root-MuiTextField-root{
  margin:auto;
}

.limbo-web{
  margin: 0 35%;
  width:500px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.limbo-top-panel{
  width: 40%;
  position: fixed;
  background: #333;
  padding: 10px 0;
  color: #fff;
  top: 0;
  z-index: 99999;
}

.limbo-top-panel-h5{
  width: 100%;
  position: fixed;
  background: #333;
  padding: 10px 0;
  color: #fff;
  top: 0;
  z-index: 99999;
}