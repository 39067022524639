.background-game-container-Seven{
  width:100%;
  height:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.content-container-inner-seven{
  width: 400px;
  height: 600px;
}

.content-container{
  width: 400px;
}

.row-col-money{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 50px;
}

.row-col-logo{
  display: flex;
  align-self: start;
  justify-content: center;
  height: fit-content;
  padding-top: 7px
}

.row-col-contents-seven{
  display: flex;
  align-self: start;
  justify-content: center;
  border-radius: 8px;
}

.row-col-order-seven{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 80px;
  border-radius: 8px;
}

.row-col-message-seven{
  display: flex;
  justify-content: center;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.customCard {
  width:100%;
  height:170px;
  background-size: 100%;
  border-radius: 8px;
  background: linear-gradient(
          to bottom,
          rgba(252,238,203,255) 0%,
          rgba(252,238,203,255) 100%
  );
}

.css-3d-text {
  font-size: 24px;
  color: #f900ac;
  font-family: "Arial Black", Gadget, sans-serif;
  text-shadow: 0px 0px 0 rgb(5,134,199),
               0px 1px  0 rgb(-48,81,146),
               0px 2px 1px rgba(40,38,51,0.42),
               0px 2px 1px rgba(40,38,51,0.5),
               0px 0px 1px rgba(40,38,51,.2);
}

/* For Desktop View */
@media screen and (min-width: 1024px) {
}

/* For Tablet View */
@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
and (orientation: portrait) {
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
and (orientation: landscape) {
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
}

.lightRotate{
  z-index: 1;
  position: relative;
  animation: rotatelight 5s linear infinite;
}

@keyframes rotatelight{
  from{ transform: rotate(0deg); }
  to{ transform: rotate(360deg); }
}

