
.mdrawer>.MuiDrawer-paperAnchorLeft{
  width: 350px;
  background-color: #0e2f4e !important;
  color: #fffefd;
}
  
.mdrawer>.MuiDrawer-paper{
    top: 45px;
    flex: 1 0 auto;
    height: 95%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
}

.msidebar-icon{
  width: 30px;
  margin-right: 15px;
  cursor: pointer;
}