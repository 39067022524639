.background-game-container{
  width:100%;
  height:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.content-container-inner{
  width: 100% !important;
  height: 600px;
  background-color: transparent !important;
}

.content-container{
  width: 400px;
}

.row-col-contents{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 120px;
  border: double 4px transparent;
  border-radius: 10px;
  /* background-image: linear-gradient(to right, #161616 , rgba(41,126,229,.75)); */
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.row-col-empty{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 30px;
}

.row-col-contents-empty{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 30px;

  background: linear-gradient(
          to bottom,
          rgba(108,111,121,255) 0%,
          rgba(108,111,121,255) 100%
  );
}

.row-col-contents-rolls{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 100px;
  border-radius: 8px;
}

.row-col-drag-bar{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 50px;

  border-radius: 8px;
}

.row-col-buttons{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 100px;
  border-radius: 10px;
  background: transparent;
}

/* For Desktop View */
@media screen and (min-width: 1024px) {

}

/* For Tablet View */
@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {

}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
and (orientation: portrait) {

}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
and (orientation: landscape) {

}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {

}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {

}

input[type="range"]::-moz-range-progress {
  background-color: #f57e00;
}
input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #f57e00;
}
input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}
