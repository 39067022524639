#gameHashdice.game-bg-web{
  width:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

#gameHashdice .innerContainer {
  max-width: 400px;
  background-color: #111923;
}

.game-bg-web::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.react-game-hashdice{
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
.react-game-hashdice input::-webkit-outer-spin-button,
.react-game-hashdice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-game-hashdice .up-smaller{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white!important;
}

.react-game-hashdice .up-smaller input{
  margin-top: 10px;
  color: white!important;
} 

.react-game-hashdice .down-bigger{
  display: flex; 
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white!important;
}

.react-game-hashdice .down-bigger input{
  margin-top: 10px;
  color: white!important;
} 

.react-game-hashdice .center-random-number{
  display: flex;
   justify-content: center;
}

.react-game-hashdice .center-random-number.col{
  height: fit-content;
}

.react-game-hashdice .center-random-number div{
  /* height: auto; */
}

.react-game-hashdice div.col{
  /* height: fit-content; */
  height: max-content;
  text-align: center;
}

.react-game-hashdice input { 
  text-align: center; 
}

.btn-up-bg-image {
  height: 55px;
  margin-right: 15px;
  background-image: url("../../assets/images/hashdice/hashdice_btn_left.png");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: right;
}

.btn-down-bg-image {
  height: 55px;
  margin-left: 15px;
  background-image: url("../../assets/images/hashdice/hashdice_btn_right.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.react-game-hashdice .buttons-group {
  display: flex;
  align-items:center;
}

.left-bigger-button{
  display: flex;
  justify-content: right;
}

.center-random-number-button{
}

.right-smaller-button{
  display: flex;
  justify-content: left;
}

.score-odd-panel table{
  text-align: center;
}

.bet-option{
  display: flex;
  justify-content: right !important;
  width: 50%;
  background-color: #111923;
}

.bet-option button{
  min-width: 45px;
} 

.top-panel {
  display:flex;
  align-items: center;
}

.top-left-panel {
  display: flex;
  justify-content: left;
  align-items: center;
}

.top-right-panel {
  display: flex;
  justify-content: right;
  align-items: center;
}

.top-right-panel img {
  width: 60px;
  height: 60px;
}

.top-left-panel .wallet-money-box {
  width:130px;
  background-color:#4C505B;
  border-radius: 15px;
  height: 50px;
  display: flex;
  justify-content: center;
  margin-left: -25px;
}

.wallet-money-box input{
  color: white;
} 

.bottom-left {
  display: flex;
  justify-content: center;
  color: white;
}

.bottom-left-bg {
  width: 95%;
  background-color: #111923;
  display: inline-flex;
}

.bottom-left-money-label {
  display: flex;
  justify-content: center;
  color: white;
  background-color: #111923;
  margin: 0 25px;
}

.bottom-left-money-value {
  width: 50%;
  display: flex;
  justify-content: center;
}

.bottom-left-money-value input{
  color: white;
  width: max-content;
}

.bottom-right-stat-label {
  color: white;
  margin: 0 10px;
}

.bottom-right-stat {
  display: flex;
  color: white;
  background-color: #111923;
  margin: 0 10px;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.bottom-right-stat input{
  color: white;
}

.bottom-panel{
  width:100%;
  background-image: url("../../assets/images/hashdice/hashdice_bottom_panel.png");
  background-repeat: no-repeat;
  background-size:contain;
  background-position: center;
}

.result-text-col {
  font-size: 18px;
  color: white;
  max-height: 30px;
  min-height: 30px;
}