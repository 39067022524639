.background-game-container-spheres{
  width:100%;
  height:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.content-container-inner-spheres{
  width: 92%;
  z-index: 9;
}

.content-container-spheres{
  width: 400px;
}

.row-col-money-spheres{
  display: flex;
  align-self: baseline;
  justify-content: center;
  height: 120px;
  padding-bottom: 20px;
}

.row-col-content-spheres{
  display: flex;
  height: 380px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: -60px 0 0 0;
}

.row-col-empty{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 30px;
}

.row-col-buttons-spheres{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 200px;
}

.background-buttons-container-spheres{
  width:100%;
  height:100%;
  /* background-image: url("../../assets/images/spheres/Betbg.png");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px; */
}

.background-results-container-spheres{
  width:100%;
  height:100%;
  background-image: url("../../assets/images/spheres/Ballbg.png");
  background-position: center;
  background-repeat: no-repeat;
}

.background-results-container-spheres-x{
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 2px 5px;
  /* background: linear-gradient(to bottom, rgb(82 74 140) 0%, rgb(78 71 116) 25%, rgb(60 54 85) 50%, rgb(48 42 79) 75%, rgb(34 30 57) 100%); */
/*  background: linear-gradient(to bottom, rgba(10,4,54,255) 0%, rgba(17,9,62,255) 25%, rgba(27,15,74,255) 50%, rgba(17,9,62,255) 75%, rgba(11,4,53,255) 100%); */
  background-image:url("../../assets/images/spheres/ballBox.png"); ;
  border-radius: 99px;
  height: 55px;
  width:100%;
}

/* For Desktop View */
@media screen and (min-width: 1024px) {

}

/* For Tablet View */
@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {

}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
and (orientation: portrait) {

}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
and (orientation: landscape) {

}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {

}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {

}

.blue {
  height: 50px;
  width: 56px;
  border-radius: 50%;
  display: inline-block;
  // background-image: url("../../assets/images/spheres/Blue.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
  animation: rotateAndScale 0.5s ease-in-out; /* 设置动画效果的持续时间和缓动函数 */
  margin-top: -4%;
  position: relative;
  z-index: 99;
}

.red {
  height: 50px;
  width: 56px;
  border-radius: 50%;
  display: inline-block;
  // background-image: url("../../assets/images/spheres/Red.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
  animation: rotateAndScale 0.5s ease-in-out; /* 设置动画效果的持续时间和缓动函数 */
  margin-top: -4%;
  position: relative;
  z-index: 99;
}

.green {
  height: 50px;
  width: 56px;
  border-radius: 50%;
  display: inline-block;
  // background-image: url("../../assets/images/spheres/Green.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
  animation: rotateAndScale 0.5s ease-in-out; /* 设置动画效果的持续时间和缓动函数 */
  margin-top: -4%;
  position: relative;
  z-index: 99;
}

.vivid {
  height: 50px;
  width: 56px;
  border-radius: 50%;
  display: inline-block;
  // background-image: url("../../assets/images/spheres/Vivid.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
  animation: rotateAndScale 0.5s ease-in-out; /* 设置动画效果的持续时间和缓动函数 */
  margin-top: -4%;
  position: relative;
  z-index: 99;
}

.yellow {
  height: 50px;
  width: 56px;
  border-radius: 50%;
  display: inline-block;
  // background-image: url("../../assets/images/spheres/Yellow.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
  animation: rotateAndScale 0.5s ease-in-out; /* 设置动画效果的持续时间和缓动函数 */
  margin-top: -4%;
  position: relative;
  z-index: 99;
}

.grey {
  height: 50px;
  width: 56px;
  border-radius: 50%;
  display: inline-block;
  // background-image: url("../../assets/images/spheres/Grey.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
  animation: rotateAndScale 0.5s ease-in-out; /* 设置动画效果的持续时间和缓动函数 */
  margin-top: -4%;
  position: relative;
  z-index: 99;
}

.cyan {
  height: 50px;
  width: 56px;
  border-radius: 50%;
  display: inline-block;
  // background-image: url("../../assets/images/spheres/Cyan.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
  animation: rotateAndScale 0.5s ease-in-out; /* 设置动画效果的持续时间和缓动函数 */
  margin-top: -4%;
  position: relative;
  z-index: 99;
}

.default{
  height: 50px;
  width: 56px;
  border-radius: 50%;
  display: inline-block;
  // background-image: url("../../assets/images/spheres/default.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;.
  margin-top: -5%;
}

.blue.active {
  border-radius:50px;
  margin-left:-47px;
  margin-top:7px;
  height:40px;
  width:40px;
  box-shadow:0 0 18px 6px #fff,0 0 20px 4px #237eec,0 0 20px 9px #237eec;
  position:absolute;
  z-index: 1;
}

.red.active {
  border-radius:50px;
  margin-left:-47px;
  margin-top:7px;
  height:40px;
  width:40px;
  box-shadow:0 0 18px 6px #fff,0 0 20px 4px #de4a45,0 0 20px 9px #de4a45;
  position:absolute;
  z-index: 1;
}

.green.active {
  border-radius:50px;
  margin-left:-47px;
  margin-top:7px;
  height:40px;
  width:40px;
  box-shadow:0 0 18px 6px #fff,0 0 20px 4px #00ff4a,0 0 20px 9px #0f1;
  position:absolute;
  z-index: 1;
}

.vivid.active {
  border-radius:50px;
  margin-left:-47px;
  margin-top:7px;
  height:40px;
  width:40px;
  box-shadow:0 0 18px 6px #fff,0 0 20px 4px #ea4c89,0 0 20px 9px #ea4c89;
  position:absolute;
  z-index: 1;
}

.yellow.active {
  border-radius:50px;
  margin-left:-47px;
  margin-top:7px;
  height:40px;
  width:40px;
  box-shadow:0 0 18px 6px #fff,0 0 20px 4px #cc66ff,0 0 20px 9px #cc66ff;
  position:absolute;
  z-index: 1;
}

.grey.active {
  border-radius:50px;
  margin-left:-47px;
  margin-top:7px;
  height:40px;
  width:40px;
  box-shadow:0 0 18px 6px #fff,0 0 20px 4px #cdbfc5,0 0 20px 9px #cdbfc5;
  position:absolute;
  z-index: 1;
}

.cyan.active{
  border-radius:50px;
  margin-left:-47px;
  margin-top:7px;
  height:40px;
  width:40px;
  box-shadow:0 0 18px 6px #fff,0 0 20px 4px #e8ec13,0 0 20px 9px #e8ec13;
  position:absolute;
  z-index: 1;
}

@keyframes pulseBlue {
  0% { border-color: #237eec; box-shadow: 0 0 1px 1px #237eec, 0 0 1px 1px #237eec inset; }
  50% { border-color: #237eec; box-shadow: 0 0 3px 3px #237eec, 0 0 3px 3px #237eec inset; }
  100% { border-color: #237eec; box-shadow: 0 0 6px 6px #237eec, 0 0 6px 6px #237eec inset; }
}
@keyframes pulseRed {
  0% { border-color: #de4a45; box-shadow: 0 0 1px 1px #de4a45, 0 0 1px 1px #de4a45 inset; }
  50% { border-color: #de4a45; box-shadow: 0 0 3px 3px #de4a45, 0 0 3px 3px #de4a45 inset; }
  100% { border-color: #de4a45; box-shadow: 0 0 6px 6px #de4a45, 0 0 6px 6px #de4a45 inset; }
}
@keyframes pulseGreen {
  0% { border-color: #36e31f; box-shadow: 0 0 1px 1px #36e31f, 0 0 1px 1px #36e31f inset; }
  50% { border-color: #36e31f; box-shadow: 0 0 3px 3px #36e31f, 0 0 3px 3px #36e31f inset; }
  100% { border-color: #36e31f; box-shadow: 0 0 6px 6px #36e31f, 0 0 6px 6px #36e31f inset; }
}
@keyframes pulseVivid {
  0% { border-color: #ea4c89; box-shadow: 0 0 1px 1px #ea4c89, 0 0 1px 1px #ea4c89 inset; }
  50% { border-color: #ea4c89; box-shadow: 0 0 2px 2px #ea4c89, 0 0 3px 3px #ea4c89 inset; }
  100% { border-color: #ea4c89; box-shadow: 0 0 6px 6px #ea4c89, 0 0 6px 6px #ea4c89 inset; }
}
@keyframes pulseYellow {
  0% { border-color: #cc66ff; box-shadow: 0 0 1px 1px #cc66ff, 0 0 1px 1px #cc66ff inset; }
  50% { border-color: #cc66ff; box-shadow: 0 0 3px 3px #cc66ff, 0 0 3px 3px #cc66ff inset; }
  100% { border-color: #cc66ff; box-shadow: 0 0 6px 6px #cc66ff, 0 0 6px 6px #cc66ff inset; }
}
@keyframes pulseGrey {
  0% { border-color: #cdbfc5; box-shadow: 0 0 1px 1px #cdbfc5, 0 0 1px 1px #cdbfc5 inset; }
  50% { border-color: #cdbfc5; box-shadow: 0 0 3px 3px #cdbfc5, 0 0 3px 3px #cdbfc5 inset; }
  100% { border-color: #cdbfc5; box-shadow: 0 0 6px 6px #cdbfc5, 0 0 6px 6px #cdbfc5 inset; }
}
@keyframes pulseCyan {
  0% { border-color: #e8ec13; box-shadow: 0 0 1px 1px #e8ec13, 0 0 1px 1px #e8ec13 inset; }
  50% { border-color: #e8ec13; box-shadow: 0 0 3px 3px #e8ec13, 0 0 3px 3px #e8ec13 inset; }
  100% { border-color: #e8ec13; box-shadow: 0 0 6px 6px #e8ec13, 0 0 6px 6px #e8ec13 inset; }
}

@-webkit-keyframes rotate {
  0% { -webkit-transform: rotate(0deg) scale(1.0); }
  100% { -webkit-transform: rotate(360deg) scale(1.1); }
}
@-moz-keyframes rotate {
  0% { -moz-transform: rotate(0deg) scale(1.0); }
  100% { -moz-transform: rotate(360deg) scale(1.1); }
}
@-o-keyframes rotate {
  0% { -o-transform: rotate(0deg) scale(1.0); }
  100% { -o-transform: rotate(360deg) scale(1.1); }
}
@-ms-keyframes rotate {
  0% { -ms-transform: rotate(0deg) scale(1.0); }
  100% { -ms-transform: rotate(360deg) scale(1.1); }
}
@keyframes rotate {
  0% { transform: rotate(0deg) scale(1.0); }
  100% { transform: rotate(360deg) scale(1.1); }
}
@-webkit-keyframes rori {
  0% { -webkit-transform: rotate(360deg) scale(1.1); }
  100% { -webkit-transform: rotate(0deg) scale(1.0); }
}
@-moz-keyframes rori {
  0% { -moz-transform: rotate(360deg) scale(1.1); }
  100% { -moz-transform: rotate(0deg) scale(1.0); }
}
@-o-keyframes rori {
  0% { -o-transform: rotate(360deg) scale(1.1); }
  100% { -o-transform: rotate(0deg) scale(1.0); }
}
@-ms-keyframes rori {
  0% { -ms-transform: rotate(360deg) scale(1.1); }
  100% { -ms-transform: rotate(0deg) scale(1.0); }
}
@keyframes rori {
  0% { transform: rotate(360deg) scale(1.1); }
  100% { transform: rotate(0deg) scale(1.0); }
}

$n: 15; // 流星數量
$s: 300; // star數量

html,body {
  height: 100%;
  overflow: hidden;
  background-image: radial-gradient(ellipse at top , #080e21 0%,  #1b2735 95%);
}

$shadow: ();

@for $i from 1 through $s {
  $x: random(1920);
  $y: random(1000);
  $shadow: $shadow, ( $x+px $y+px #fff) !global;
}

.star {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadow;
}

@for $i from 1 through $n {
  $v: random(90) + 9; // left
  $h: random(250) + 50; // top
  $d: random(70)/10 + 3; // 秒數
  .meteor-#{$i} {
    position: absolute;
    top: $h + px;
    left: $v*2%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
    animation: meteor $d+s linear  infinite;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      height: 5px;
      border-radius: 50%;
      margin-top: -2px;
      background: rgba(#fff,.7);
      box-shadow: 0 0 15px 3px #fff;
    }
    
  }
}

@keyframes meteor {
  0% {
    opacity: 1;
    margin-top: -300px;
    margin-right: -300px;
  }
  12% {
    opacity: 0;
  }
  15% {
    margin-top: 300px;
    margin-left: -600px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotateAndScale {
  0% {
    transform: scale(0.5) rotate(0deg); /* 初始缩小50% */
  }
  100% {
    transform: scale(1) rotate(360deg); /* 最终大小和旋转一圈(360度) */
  }
}

/* css for odd box (box-shadow in and out) */
@keyframes glowing-in-out-sp {
  0% { border-color: #743ad5; box-shadow: 0 0 6px 3px #743ad5, 0 0 6px 6px #743ad5 inset; }
  50% { border-color: #743ad5; box-shadow: 0 0 6px 3px #d53a9d, 0 0 6px 6px #d53a9d inset; }
  100% { border-color: #743ad5; box-shadow: 0 0 6px 3px #743ad5, 0 0 6px 6px #743ad5 inset; }
}