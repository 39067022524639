#gameTriple.game-bg-web{
  width:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.game-bg-web::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

#gameTriple .innerContainer {
  max-width: 400px;
  /* background-color: #111923; */
  /* background: url('../../assets/images/triple/triple_bg.png'); */
}

.react-game-triple{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
.react-game-triple input::-webkit-outer-spin-button,
.react-game-triple input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.react-game-triple input[type=number] {
  -moz-appearance: textfield;
}

.react-game-triple input{
  color:white;
  text-align: center;
}

.react-game-triple button{
  color: white !important;;
}

.react-game-triple div.container-fluid {
  
}

.react-game-triple div.container-fluid div.col {
  display: flex;
  justify-content: center;
}

.center-ball-panel div.col{
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-self: center; */
}

.center-ball-panel div.row{
  margin: 2px 0;
}

.center-ball-panel div.col img{
  margin: 0 2px;
  width: calc(100vw/6);
  max-width: 60px;
}

.center-selected-ball {
  border: purple solid 2px;
  border-radius: 5px;
}

.reward-guide-row {
  /* padding-left: 10px !important; */
  /* padding-right: 10px !important; */
  /* margin-left: 10px !important;
  margin-right: 10px !important; */
}

.reward-guide-space, .reward-guide-space-selected {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 2px;
  height: 35px;
}

.reward-guide-space img {
  width: 100%;
  height: 50px;
  max-width: calc(360px/5) !important;
}

.reward-guide-space-selected img {
  width: 100%;
  height: 54px;
  background-color: #0a6c6c;
  max-width: calc(360px/5) !important;
}

.reward-guide-empty-row {
  height: 15px !important;
}

.reward-dynamic-odd {
  color: white;
  position: absolute;
  bottom: 0 !important;
  font-size: 10px;
}

.bottom-panel-row {
  background-color:#444A5C;
  min-height: 35px;
}

.bottom-panel-col-left {
  justify-content: left!important;
  max-width: 20%!important;
  margin: 0 0;
}

.bottom-panel-col-left button {
  
}

.bottom-panel-col-mid {
  width: auto!important;
  padding: 0 0;
  margin: 0 0;
}

.bottom-panel-col-mid input{
 min-width:100%; 
}

.bottom-panel-col-right {
  justify-content: right!important;
  max-width: 20%!important;
  margin: 0 0;
}

.bottom-panel-col-right button {
}

.bottom-label-bet-amount {
  width: auto!important;
  padding: 0 0;
  margin: 0 0;
}

.bottom-label-bet-amount span {
  color:#737785;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
}

.bottom-btn-low {
  justify-content: left!important;
}

.bottom-btn-low button {
  width: 100%;
}

.bottom-btn-medium button{
  width: 100%;
}

.bottom-btn-high {
  justify-content: right!important;
}

.bottom-btn-high button {
  width: 100%;
}

.bottom-btn-selected button{
  background-color: #8D3AFC!important;
}

.bottom-btn-clear button{
  width: 100%;
  background-color: #FF6C52!important;
}

.bottom-btn-play button{
  width: 100%;
  background-color: #8D3AFC!important;
}

.bottom-banner-result-col {
  
  height: 50px;
}

.bottom-result-col {
  display: flex;
  align-items: center !important;
  font-size: 20px;
  color: white;
  /* height: 70px; */
}

.odd-box-background {
  width: 118%;
  margin-left: -9%;
  margin-top: -7%;
  height: 150px;
}

.btn-background-display {
  background: url('../../assets/images/triple/btn_mediumdisplay.png');
}
.btn-background-selected {
  background: url('../../assets/images/triple/btn_mediumselect.png');
}

.number-button {
  width: 50px;
}

.bet-input-background {
  background: url('../../assets/images/triple/bet_amount.png');
}
.bet-input-background div fieldset {
  border: none;
}

.button-background-setting {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.bet-button {
  margin: -2% !important;
}

.game-box {
  background: url('../../assets/images/triple/box_middle.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -5px;
}

.risk-button {
  width: 35%;
  padding: 0 !important;
  margin: -4% -1% 0 !important;
}
.risk-img {
  width: 100%;
}

.loadingSpinner{
  fill: white;
  width: 15%;
  /* background-color: black; */
  background-color: transparent;
  border-radius: 14%;
  position: fixed;
  top: 44%;
  opacity: .8;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: all 0.5s;
  padding:5px
}

.btn-click-effect:active {
  transition: 0.2s;
  transform: scale(0.93);
}

.border-gradient {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d) !important;
  border: 3px solid;
  border-image-slice: 1;
}


.after-win-box-animation {
  -webkit-animation: glowing-in-out 1500ms infinite, fadeIn 1s;
  -moz-animation: glowing-in-out 1500ms infinite, fadeIn 1s;
  -o-animation: glowing-in-out 1500ms infinite, fadeIn 1s;
  animation: glowing-in-out 1500ms infinite, fadeIn 1s;
  border-radius: 5px;
}
.after-win-animation {
  -webkit-animation: fadeIn 1s, wiggle 2s ease-in-out infinite;
  -moz-animation: fadeIn 1s, wiggle 2s ease-in-out infinite;
  -o-animation: fadeIn 1s, wiggle 2s ease-in-out infinite;
  animation: fadeIn 1s, wiggle 2s ease-in-out infinite;
  border-radius: 5px;
}

/* border glowing animation */
.odd-box-glow-in-out {
  -webkit-animation: glowing-in-out 1500ms infinite;
  -moz-animation: glowing-in-out 1500ms infinite;
  -o-animation: glowing-in-out 1500ms infinite;
  animation: glowing-in-out 1500ms infinite;
}

/* css for odd box (box-shadow in and out) */
@-webkit-keyframes glowing-in-out {
  0% { border-color: #743ad5; -webkit-box-shadow: 0 0 12px 6px #743ad5, 0 0 12px 6px #743ad5 inset; }
  50% { border-color: #d53a9d; -webkit-box-shadow: 0 0 12px 10px #d53a9d, 0 0 12px 6px #d53a9d inset; }
  100% { border-color: #743ad5; -webkit-box-shadow: 0 0 12px 6px #743ad5, 0 0 12px 6px #743ad5 inset; }
}

@-moz-keyframes glowing-in-out {
  0% { border-color: #743ad5; -moz-box-shadow: 0 0 12px 6px #743ad5, 0 0 12px 6px #743ad5 inset; }
  50% { border-color: #d53a9d; -moz-box-shadow: 0 0 12px 6px #d53a9d, 0 0 12px 6px #d53a9d inset; }
  100% { border-color: #743ad5; -moz-box-shadow: 0 0 12px 6px #743ad5, 0 0 12px 6px #743ad5 inset; }
}

@-o-keyframes glowing-in-out {
  0% { border-color: #743ad5; box-shadow: 0 0 12px 6px #743ad5, 0 0 12px 6px #743ad5 inset; }
  50% { border-color: #d53a9d; box-shadow: 0 0 12px 6px #d53a9d, 0 0 12px 6px #d53a9d inset; }
  100% { border-color: #743ad5; box-shadow: 0 0 12px 6px #743ad5, 0 0 12px 6px #743ad5 inset; }
}

@keyframes glowing-in-out {
  0% { border-color: #743ad5; box-shadow: 0 0 12px 6px #743ad5, 0 0 12px 6px #743ad5 inset; }
  50% { border-color: #d53a9d; box-shadow: 0 0 12px 6px #d53a9d, 0 0 12px 6px #d53a9d inset; }
  100% { border-color: #743ad5; box-shadow: 0 0 12px 6px #743ad5, 0 0 12px 6px #743ad5 inset; }
}

.delay3s-animation {
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
}

/* ball result fade animation */
@-moz-keyframes fadeIn { 
  0% { opacity: 0; }
  100% { opacity: 1; } 
}
@-webkit-keyframes fadeIn { 
  0% { opacity: 0; }
  100% { opacity: 1; } 
}
@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeIn { 
  0% { opacity: 0; }
  100% { opacity: 1; } 
}

/* wiggle animation for selected icon after 开奖 */
@-webkit-keyframes wiggle {
  0% {-webkit-transform: rotate(10deg);}
  25% {-webkit-transform: rotate(-10deg);}
  50% {-webkit-transform: rotate(10deg);}
  75% {-webkit-transform: rotate(-10deg);}
  100% {-webkit-transform: rotate(10deg);}
}
@-moz-keyframes wiggle {
  0% {-webkit-transform: rotate(10deg);}
  25% {-webkit-transform: rotate(-10deg);}
  50% {-webkit-transform: rotate(10deg);}
  75% {-webkit-transform: rotate(-10deg);}
  100% {-webkit-transform: rotate(10deg);}
}
@-o-keyframes wiggle {
  0% {-webkit-transform: rotate(10deg);}
  25% {-webkit-transform: rotate(-10deg);}
  50% {-webkit-transform: rotate(10deg);}
  75% {-webkit-transform: rotate(-10deg);}
  100% {-webkit-transform: rotate(10deg);}
}
@keyframes wiggle {
  0% {transform: rotate(10deg);}
  25% {transform: rotate(-10deg);}
  50% {transform: rotate(10deg);}
  75% {transform: rotate(-10deg);}
  100% {transform: rotate(10deg);}
}


@media only screen and (max-width: 428px) {
  .react-game-triple div.game-main-container div.row{
    /* padding-left: 10px !important;
    padding-right: 10px !important; */
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .center-ball-panel div.row{
    margin: 2px 10px;
  }

  .center-ball-panel div.col img{
    margin: 0 2px;
    width: calc(90vw/6);
    max-width: 50px;
  }

  .reward-guide-space img {
    width: 95%;
    height: 54px;
    max-width: calc(340px/5) !important;
  }
  
  .reward-guide-space-selected img {
    width: 95%;
    height: 54px;
    background-color: #0a6c6c;
    max-width: calc(340px/5) !important;
  }

  .reward-guide-row {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .bottom-panel-row {
    /* display: flex; */
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

}