
.background-image{
  
  width:100%;
  /* height: 200px; */
  background-image: url("../../assets/images/hashdice/hashdice_center.png");
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.award-number {
  font-size: 60px;
  padding-bottom: 20px;
  display: flex;
  color: white !important;
  z-index: 100;
}