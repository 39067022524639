
.content-container-inner{
  width: 92%;
  height: 600px;
}

.content-container{
  width: 400px;
}

.row-col-empty{
  display: flex;
  align-self: start;
  justify-content: center;
  height: 50px;
}

.diamond-rate{
  border: 2px solid hsla(0,0%,50%,.769);
  border-radius: 10px;
  color: #fff;
  height: auto;
  margin: -7px 0;
  padding: 10px 15px;
  width: 100%;
}

.diamond-rate.active{
  -webkit-animation: glowing-in-out 1.5s infinite;
  animation: glowing-in-out 1.5s infinite;
}

.diamond-rate-row{
  width:100%;
  height: 30px;
  padding:2px 0 0 0;
}
.diamond-active{
  // background-color: rgb(24,23,44);
  border-radius: 30px;
}
.diamond-rate-colWord{
  text-align: right;
  margin: 0px;
  padding: 0px;

}

.diamond-rate-colWord.active{
  color: #ffc845;

}

.diamond-rate-colDiamond img{
  width: 30px;
  margin-left: 7px;
}

.diamond-body-result{
  background-color: #000000;
  border-radius: 50px;
  color: #ffc845;
  font-size: 16px;
  justify-content: center;
  margin: 3% 5%;
  min-height: 45px;
  padding: 10px 0;
  text-align: center;
  text-shadow: 0 0 5px #fef47f;
  width: 90%;
}

.diamond-bet-amount-body {
  margin: 15px 35px 0 35px;
}

.diamond-bet-amount{
  text-align: center;
  // background-color: rgb(71, 72, 79);
  border-radius: 5px;
  font-size: 20px;
  line-height: 2;
  color: white;
  align-self: start;
  justify-content: center;
  margin: 25px 0 -22px 0;
}

.diamond-body-bet-amount{
  align-self: start;
  justify-content: center;
  height: fit-content;
  border: 2px solid #f2ad5780;
  border-radius: 10px;
  // background-image: linear-gradient(to right, #161616 , rgba(41,173,229,.75));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.diamond-body-bet-button{
  justify-content: center;
  // padding-top: 5px;
  // padding-left: 20px;
}

.diamond-body-bet-play{
  float: left;
  justify-content: center;
  margin: 10px 0;
  padding-top: 5px;
  width: 100%;
}

.lg-text{
  // padding: 10px 10px;
  // margin-top: 4px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  color: #F2B357;

}

.lg-button{
  padding: 10px 10px;
  margin-top: 4px;
  border-radius: 5px;
  font-size: 16px;
  max-width: 50px;
  line-height: 1;
  text-align: center;
  background-color: #55acee;
  box-shadow: 0px 5px 0px 0px #3C93D5;
}

.lg-button:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  box-shadow: 0px 1px 0px 0px;
}

.diamond-resultshow{
  padding-top: 20px;
  // padding-left: 15px;
  // margin-right: auto;
  // margin-left: auto;
}

.diamond-resultshow-base{
  // padding-top: 20px;
  //padding-left: 15px;
  // margin-right: auto;
  // margin-left: auto;
}

.diamond-resultshow img{
  width: 63px;
  height: 25px;
}
.diamond-resultshow-base img.box{
  display: block;
  height: 6vh;
  margin-left: -10px;
  position: relative;
  width: 130%;
}
.diamond-resultshow img.result1{
  width: 50px;
  -webkit-animation: shakingUp 1s infinite;
  height: 50px;
}
.diamond-resultshow img.result2{
  width: 50px;
  -webkit-animation: shakingDown 1s infinite;
  height: 50px;
}
.diamond-resultshow img.result3{
  width: 50px;
  -webkit-animation: shakingUp 1s infinite;
  height: 50px;
}
.diamond-resultshow img.result4{
  width: 50px;
  -webkit-animation: shakingDown 1s infinite;
  height: 50px;
}
.diamond-resultshow img.result5{
  width: 50px;
  -webkit-animation: shakingUp 1s infinite;
  height: 50px;
}

.diamond-resultshow img.result_light_1{
  position: absolute;
    width: 80px;
    height: 85px;
    margin: -19.5px -17px;
  -webkit-animation: shakingUp 1s infinite;
}
.diamond-resultshow img.result_light_2{
  position: absolute;
    width: 80px;
    height: 85px;
    margin: -19.5px -17px;
  -webkit-animation: shakingDown 1s infinite;
}
.diamond-resultshow img.result_light_3{
  position: absolute;
    width: 80px;
    height: 85px;
    margin: -19.5px -17px;
  -webkit-animation: shakingUp 1s infinite;
}
.diamond-resultshow img.result_light_4{
  position: absolute;
    width: 80px;
    height: 85px;
    margin: -19.5px -17px;
  -webkit-animation: shakingDown 1s infinite;
}
.diamond-resultshow img.result_light_5{
  position: absolute;
    width: 80px;
    height: 85px;
    margin: -19.5px -17px;
  -webkit-animation: shakingUp 1s infinite;
}

.result-group {
  width: 20%;
  display: block; /* Make the buttons appear below each other */
  // padding: 0 2%;
  position: relative;
}

@keyframes shakingUp{
  0%{
    transform: translateY(-2px);
  }
  50%{
    transform: translateY(2px);
  }
  100%{
    transform: translateY(-2px);
  }
}

@keyframes shakingDown{
  0%{
    transform: translateY(2px);
  }
  50%{
    transform: translateY(-2px);
  }
  100%{
    transform: translateY(2px);
  }
}

.diamond-top-panel{
  width: 100%;
  z-index: 999999;
}

.diamond-coin{
  max-width: 58px;
  max-height: 50px;
  /* z-index: 100; */
  margin: 0 10px 0 0;
}

.top-right-panel img {
  max-width: 45px;
  max-height: 45px;
}

.play-history-img {
  max-width: 45px;
  max-height: 45px;
}

.wallet-money-box input{
  text-align: center;
}

.coefficient-item {
  border-radius: 6px;
  margin-bottom: 6px;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

@media screen and (min-width: 960px){
.coefficient-item {
    padding: 4px 12px;
  }
}

.dots {
  display: flex;
}

.dots div.first {
  background-color: rgb(128, 142, 168);
  border-color: rgb(128, 142, 168);
}

.dots div:first-child {
  margin-left: 0px;
}
.dots div {
  width: 14px;
  height: 14px;
  margin: 6px 6px;
  border-radius: 50%;
  border: 1px solid rgb(84, 104, 146);
}

.dots div.second {
  background-color: rgb(60, 74, 100);
  // border-color: rgb(60, 74, 100);
}

.dots div:last-child {
  margin-right: 0px;
}

.diammond-play-button{
    font-weight: bold;
    font-size: 16px;
    margin: 4px;
    cursor: pointer;
    width: 160px;
    height: 55px;
    padding: 15px;
    // background: linear-gradient(rgb(255, 49, 96) 10%, rgb(255, 49, 96) 20%, rgb(255, 49, 96) 50%, rgb(255, 49, 96) 70%, rgb(255, 49, 96) 100%);
    text-align: center;
    background: transparent;
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 2px solid transparent;
}

.diammond-play-button.autoPlay{
  background-image: linear-gradient(90deg,#202020,#202020),linear-gradient(90deg,#fccf31,#f55555);
    border-bottom-left-radius: 8px;
    border-right: none;
    border-top-left-radius: 8px;
    color: #e28f29;
    float: left;
    margin: 0px;
    width: 50%;
}

.diammond-play-button.play{
  color: white;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-image: linear-gradient(113.9deg,#ff0707 -4.5%,#ffff05 98.8%),linear-gradient(270deg,#fccf31,#f55555);
  border-left: none;
  float: right;
  margin: 0 0%;
  width: 50%;
}

.diammond-play-button.play.active{
  opacity: 0.5;
}

.diammond-play-button.autoPlay.active{
  opacity: 0.5;
}

.result_light_0.show {
  display: block;
}
.result_light_1.show {
  display: block;
}
.result_light_2.show {
  display: block;
}
.result_light_3.show {
  display: block;
}
.result_light_4.show {
  display: block;
}
.result_light_5.show {
  display: block;
}
.result_light_6.show {
  display: block;
}

.result_light_0.hide {
  display: none;
}
.result_light_1.hide {
  display: none;
}
.result_light_2.hide {
  display: none;
}
.result_light_3.hide {
  display: none;
}
.result_light_4.hide {
  display: none;
}
.result_light_5.hide {
  display: none;
}
.result_light_6.hide {
  display: none;
}

.css-1srd2yf input {
  color: #ffc845;
  text-shadow: 0px 0px 5px #FEF47F;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

