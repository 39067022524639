.background-game-container-keno{
  width:100%;
  height:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.content-container-inner-keno{
  width: 400px;
  height: 650px;
  background-color: #111923;
}

.content-container-keno{
  width: 400px;
}

.row-col-money-keno{
  display: flex;
  align-self: center;
  justify-content: center;
  padding-bottom: 10px;
}

.row-col-title-keno{
  display: flex;
  align-self: start;
  justify-content: center;
  width: 20%;
}

.row-col-table-keno{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100px;
  padding-bottom: 20px;
}

.row-col-content-keno{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 250px;
  padding-top: 30px;
}

.row-col-result-keno{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.row-col-result2-keno{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.row-col-button-keno{
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

/* For Desktop View */
@media screen and (min-width: 1024px) {

}

/* For Tablet View */
@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {

}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
and (orientation: portrait) {

}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
and (orientation: landscape) {

}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {

}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {

}
