.background-web {
  width:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/web_background.jpg");
}

.h5-header{
  background-color: rgb(7, 33, 58);
  height: 50px;
  line-height: 45px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.language-and-euro-btn {
  float: right;
  margin-top: -5px;
  margin-left: 10px;
  position: absolute;
  right: 20px;
}

.language-and-euro-btn  .btn-secondary{
  /* color: #fff; */
  background-color: transparent;
  border-color: transparent;
}

.lang-dropdown{
  background: #ffffff00 !important;
  border-radius: 99px !important;
  font-size: 13px !important;
  border: 2px solid #ffffff45 !important;
  width: 105px;
  padding: 0.175rem 0.75rem;
}

.lang-dropdown:focus{
outline: none;
box-shadow: none;
}

.lang-dropdown img, .lang-dropdown-menu img{
width: 25px;
height: 15px;
}

.lang-dropdown-menu{
font-size: 12px;
}

.hall-container{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width:1140px;
  overflow-y: scroll;
  position: relative;
  height: 50vh;
}

.hall-container-openGame{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width:1140px;
}

.hall-container-head{
  font-family: "Work Sans", sans-serif;
  color:#fff;
  font-size: 22px;
  margin:25px 0 -15px 0;
  position:relative;
}

.hall-row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 30px;
}

.hall-col-card{
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 20px
}

.hall-col-card-img{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  flex-shrink: 0;
  width: 100%;
}

.hall-col-card .card-body{
  padding: 7px 15px;
  flex: 1 1 auto;
  background-color: #0f0527;
  color:#fff;
}

.mobileHeader{
  background-size:cover;
  height: 9%;
  width: auto;
  position: relative;
}

.mobile-userdetails{
  margin-top:15px;
  background-size:cover;
  height:70px;
}

.mobile-hall-container{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width:1140px;
  margin-top: 15px;
}

.mobile-hall-container-head{
  font-family: "Work Sans", sans-serif;
  color:#fff;
  font-size: 22px;
}

.mobile-hall-row{
  display: flex;
  flex-wrap: wrap;
  color: white;
}

.mobile-hall-col-card{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 20px
}
  
.mobile-hall-col-card-img{
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.window-pop-up{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: block!important;
}

.window-pop-up-img{
  position: absolute;
  top: 20%;
  padding: 2%;
  border-radius: 8px;
  width:100%;
  background-size:100% 100%;
}

.window-pop-up-back{
  width: 60px;
  height: 60px;
  position: absolute;
  right: 25px;
  z-index: 10;
}

.window-pop-up-context{
  position: absolute;
  top: 10%;
  padding: 20px;
  color: white;
  width: 95%;
  text-align: center;
}

.window-pop-up-select{
  position: absolute;
  top: 25%;
  padding: 20px;
  color: white;
  width: 95%;
  text-align: center;
  font-size: 24px;
}

.window-pop-up-select .row {
  width:100%;
  height:40%;
  margin: 10px 0 0 0;
}

.window-pop-up-option{
  background-image: url("../../assets/images/h5/popup-option-bg.png");
  width:100%;
  height:40%;
  background-size:100% 100%;
}

.window-pop-up-selected{
  background-image: url("../../assets/images/h5/popup-selected-bg.png");
  width:100%;
  height:40%;
  background-size:100% 100%;
}

.hall-container-body {
  width: 100vw;
  height: 100vh;
}

.iframeUI{
  width: 1250px;
  /* height: 888px; */
  height: 83%;
  margin-top: 25px;
}


.row-col-money{
  display: flex;
  align-self: center;
  justify-content: center;
}

.carouselStyle .slick-list {
  height: 100%;
  border-radius: 15px;
}

.carouselStyle .slick-dots {
  bottom: 10px;
  display: none !important;
}

.carouselStyle.mini .slick-list {
  border-radius: 0;
}

.carouselStyle.mini img {
  border-radius: 15px;
  width: calc(100% - 5px);
  margin: auto;
  height: auto;
}

.slick-slider.carouselStyle.mini.slick-initialized{
    width: 80%;
    margin: 2% 10% 0 10%;
}

.slick-slider.carouselStyle.mini-mobile.slick-initialized{
  margin: 2% 0 2% -5%;
  width: 110%;
}

.carouselStyle.mini-mobile img {
  border-radius: 15px;
  width: calc(100% - 5px);
  margin: auto;
  height: auto;
}

.slick-slide div {
  outline: none;
}