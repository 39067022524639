.modal-backdrop.show {
  opacity: 0;
}

.play-history-img {
  max-width: 45px;
  max-height: 45px;
  cursor: pointer;
}

.play-history-modal {
  
}

.play-history-modal .modal-dialog{
  margin-top: 10vh;
}

@media only screen and (min-device-width: 415px) {

  .play-history-modal {  
    display: flex !important;
    justify-content: center;
    align-items:baseline;
  }

  .play-history-modal .modal-dialog {
    width: 80% ;
    max-width: 80% !important;
  }
}

.play-history-modal div {
  background-color: transparent;
}

.play-history-modal .modal-content{
}

.play-history-modal .modal-body {
  height: fit-content;
  display: flex;
  justify-content: center;
  padding: 0 0;
}

.play-history-modal .container-fluid {
  color: white;
}

.play-history-modal .container-fluid .row {
  min-height: 30px;
}

.play-history-modal .container-fluid .col,.col-1,.col-2,.col-3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade.play-history-modal {
  // display: none;
}

.play-history-modal .record-title-row {
  height: 35px;
  font-size: 20px;
  font-weight: bold;
  background: rgba(30, 30, 30, .9);    /*  10% opaque green */ 
}

.play-history-modal .record-header-row {
  font-size: 0.7rem;
  background: rgba(123, 123, 123, .9);
  text-align: center;
}

.play-history-modal .record-header-row .col,.col-1,.col-2,.col-3{
  padding: 0 0;  
}

.play-history-modal .record-data-row {
  font-size: 0.7rem;
  background: rgba(150, 150, 150, .9);
}

.play-history-modal .record-data-row .col,.col-1,.col-2,.col-3{
  padding: 0 0;
}

//provably-fair-modal

.provably-fair-modal .modal-dialog{
  margin-top: 10vh;
}

@media only screen and (min-device-width: 415px) {

  .provably-fair-modal {  
    display: flex !important;
    justify-content: center;
    align-items:baseline;
  }

  .provably-fair-modal .modal-dialog {
    width: 80% ;
    max-width: 80% !important;
  }
}

.provably-fair-modal div {
  background-color: transparent;
}

.provably-fair-modal .modal-content{
}

.provably-fair-modal .modal-body {
  height: fit-content;
  display: flex;
  justify-content: center;
  padding: 0 0;
}

.provably-fair-modal .container-fluid {
  color: white;
  background-color: #252A3D;
}

.provably-fair-modal .container-fluid .row {
  min-height: 30px;
}

.provably-fair-modal .container-fluid .col,.col-1,.col-2,.col-3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable-div {
  cursor: pointer;
}

.provably-fair-modal .record-title-row {
  height: 35px;
  font-size: 20px;
  font-weight: bold;
  // background: rgba(30, 30, 30, .9)    /*  10% opaque green */ 
}

.fade.provably-fair-modal {
  // display: none;
  // background: rgba(255, 255, 255, .9)
}

.provably-fair-modal .record-content-row input {
  color: white;
  text-align: center;
  width: 100%;
}

.provably-fair-modal .record-content-row textarea {
  color: white;
  text-align: center;
  width: 100%;
}

.provably-fair-modal .record-result-row div {
  width: 100%;
}

.provably-fair-modal .record-result-row textarea {
  color: white;
  text-align: center;
  padding: 0 0;
}