#gameLuckycircle.game-bg-web{
  width:100%;
  background-color: #111923;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

}

.game-bg-web::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

#gameLuckycircle .innerContainer {
  max-width: 400px;
  background-color:#111923;
}

.react-game-lucky-circle{
  display: flex;
  justify-content: center;
  align-items: center;
  color:white; 
}

.react-game-lucky-circle div.container-fluid {
  padding: 0 1px;
  margin: 0 1px;
}

/* Chrome, Safari, Edge, Opera */
.react-game-lucky-circle input::-webkit-outer-spin-button,
.react-game-lucky-circle input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.react-game-lucky-circle input[type=number] {
  -moz-appearance: textfield;
}

.react-game-lucky-circle input { 
  text-align: center;
}

.react-game-lucky-circle button{
  color: white;
}

.react-game-lucky-circle div.container-fluid div.col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 0;
}

.react-game-lucky-circle div.container-fluid div.row {
  min-height: 30px;
  padding: 0 0 0 0;
  margin: 0 0;
}

.top-money-row {
  background-color: #4D2048;
  height: auto;
  margin: 0 0 0 0;
  width: 100%;
  align-items: center !important;
}

.top-money-row img{
  height: 30px;
  margin-left: -5px;
}

.top-label-money {
  margin: 0 10px;
  width: 50%;
  text-align: center;
  font-weight: bold;
  font-size: larger;
}

.top-label-other {
  color: #E7B009;
  font-size: 51%;
  text-align: center;
}

.top-label-value {
  background-color: #684163;
  margin: 0 10px;
  width: 50%;
  text-align: center;
}

.center-ball-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*max width 400px */
.center-ball-row img{
  width: 100%;
  max-width: calc(400px/10);
}

.lucky-circle-center-balls{
  margin: 10px 0;
}

.bottom-star-list-row {
  background-color: #4D2048;
  height: 40px;
  color: #E7B009;
}

.bottom-star-list-row img{
  height: 25px;
}

.lucky-circle-bottom-panel-col {
  justify-content: center;
  /* align-items: center !important; */
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.lucky-circle-bottom-left-panel {
  background-color: #5B1647;
  width: 100%;
  height: 100%;
}

.bottom-name-amount-payout-win-row {
  font-size: 11px;
  color:#F76479;
}

.bottom-list-result-row {
  border-bottom: 1px solid #F76479;
  background-color: #5B1647;
}

.bottom-list-result-row div.col,div.col-md-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: small; */
}

.scrollable-users-list {
  width: 100%;
  height: 250px;
  overflow-y: scroll;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scrollable-users-list::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.lucky-circle-bottom-left-panel div.row{
  /* margin-left: 0;
  margin-right: 0; */
}



.lucky-circle-bottom-right-panel {
  background-color: #243B46;
}

.lucky-circle-bottom-right-panel div.row{
  /* margin-left: 0;
  margin-right: 0; */
}

.lucky-circle-bottom-col1 {
  /* width: max-content; */
 display: flex;
 justify-content: center !important;
 min-width: 30% !important;
 padding: 0 0;
 margin:  0 0;
}

.lucky-circle-bottom-col3 {
  /* width: auto; */
  display: flex;
  justify-content: center !important;
  min-width: 30% !important;
  padding: 0 0;
  margin:  0 0;
}

.lucky-circle-bottom-col-amount-value {
  font-size: 15px !important;
  border-bottom: grey solid 2px;
  min-width: 20% !important;
}

.lucky-circle-bottom-col-amount-value input{
  color: white;
}

.lucky-circle-bottom-col-amount-label {
  color: rgb(171, 171, 171) !important;
  /* font-weight: bold !important; */
  font-size: 10px !important;
}

.lucky-circle-bottom-col3 button{
  min-width: 60% !important;
  width: 100%;
  /* margin: 0 1px; */
}

.lucky-circle-bottom-col1 button{
  min-width: 60% !important;
  width: 100%;
  /* margin: 0 1px; */
}

.bottom-control-start-col {
  /* margin: 0 0 0 0; */
  /* padding: 0 0 0 0; */
  display: flex;
  justify-content: center !important;
}

/* .bottom-control-start{
  color: black !important;
  font-size: 20px !important;
  font-weight: bold !important;
  padding: 0 0;
} */

.bottom-control-start {
  background-color: #F4C32C !important;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 90%;
  margin: 0 0;
  padding: 0 0;
  color: black !important;
  font-size: 20px !important;
  font-weight: bold !important;
  padding: 0 0;
}

.bottom-control-end {
  background-color: #BCBCBB !important;
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 90%;
  margin: 0 0;
  padding: 0 0;
  color: black !important;
  font-size: 20px !important;
  font-weight: bold !important;
  padding: 0 0;
}

.reset-game-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.reset-game-layer img {
  
  width: 50%;
}

.tile-open-image, .tile-open-value {
  grid-column: 1;
  grid-row: 1;
}

.tile-open-image {
  /* position: relative; */
}

.tile-open-value {
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  z-index: 100;
  /* left: -30px; */
}

@media only screen and (max-width: 428px) {

  .react-game-lucky-circle div.game-main-container {
    padding: 0 5px;
    margin: 0 5px;
  }

  .center-ball-row img{
    /* width: 40px; */
    /* height: 40px */
    /* height: calc(100%/10); */
    width: 90%;
    max-width: calc(90vw/10);
  }

  .bottom-name-amount-payout-win-row {
    font-size: 10px;
  }

  .bottom-name-amount-payout-win-row div.col,div.col-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-list-result-row {
    font-size: 11px;
  }

  .bottom-list-result-row div.col-2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    
  }
}
